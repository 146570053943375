import React from 'react';
import styled from 'styled-components';

import {
  ContentfulComponentBackground,
  ContentfulPageLanding,
  ContentfulEntry,
} from '@/../graphql-types';
import BackgroundWrapper from '@/components/BackgroundWrapper';
import {
  FooterCta, // opt. FooterCtaTall
  CustomerLogos,
  EventCard,
  EventCardSet,
  HeroBanner,
  PlatformFeatureSet,
  PromoPopUp,
  ResourceCardSet,
  SolutionsCardCarousel,
  Undefined,
  RegistrationForm,
} from '@/components/contentful/components';
import * as Constants from '@/types/Constants';

type BackgroundComponent = ContentfulEntry & {
  background: ContentfulComponentBackground;
};

const Container = styled.div``;

interface PageLandingProps {
  data?: ContentfulPageLanding;
}
const PageLanding: React.FC<PageLandingProps> = (props) => {
  const { data } = props;

  // if the component has a background, then wrap it with a background
  const wrapBackground = (component, element) => {
    const hasBackground = component?.['background'] != null;
    if (hasBackground) {
      return (
        <BackgroundWrapper
          key={`background-component-${component.id}`}
          data={(component as BackgroundComponent)?.background}
        >
          {element}
        </BackgroundWrapper>
      );
    } else {
      return element;
    }
  };

  return (
    <Container className="">
      {data.pageHeader &&
        wrapBackground(
          data.pageHeader,
          <HeroBanner data={data.pageHeader as ContentfulEntry} />,
        )}
      {data.sections?.map((contentfulComponent: ContentfulEntry) => {
        // determine which contentful component should be used
        let element;
        const key = `component-${contentfulComponent.id}`;
        switch (contentfulComponent?.internal?.type) {
          case Constants.COMPONENT_CUSTOMER_LOGOS:
            element = <CustomerLogos key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_EVENT_CARD:
            element = <EventCard key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_EVENT_CARD_SET:
            element = (
              <EventCardSet
                key={key}
                data={contentfulComponent}
                columns={true}
              />
            );
            break;
          case Constants.COMPONENT_HERO_BANNER:
            element = <HeroBanner key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_PLATFORM_FEATURE_SET:
            element = (
              <PlatformFeatureSet key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_PROMO_POPUP:
            element = <PromoPopUp key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_RESOURCE_CARD_SET:
            element = <ResourceCardSet key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_SOLUTIONS_CAROUSEL:
            element = (
              <SolutionsCardCarousel key={key} data={contentfulComponent} />
            );
            break;
          default:
            element = (
              <Undefined
                key={key}
                message={`ComponentMapper: "${contentfulComponent?.internal?.type}" not defined.`}
              />
            );
        }

        return wrapBackground(contentfulComponent, element);
      })}
      {/* {data.footerCta &&
        wrapBackground(data.footerCta, <FooterCtaTall data={data.footerCta} />)} */}
      <FooterCta data={data.footerCta} />
      {data?.contactForm && <RegistrationForm data={data?.contactForm} />}
    </Container>
  );
};
export default PageLanding;
