import React from 'react';
import { graphql, PageProps } from 'gatsby';
import {
  ContentfulComponentSiteFooter,
  ContentfulComponentSiteHeader,
  ContentfulComposePage,
  ContentfulComposeSeo,
  ContentfulPageLanding,
  LandingPageQuery,
} from '@/../graphql-types';
import Layout from '@/components/layout/Layout';
import SEO from '@/components/SEO';
import * as Constants from '@/types/Constants';
import PageLanding from '@/components/contentful/pages/Landing';

export const query = graphql`
  query LandingPage($id: String!, $node_locale: String!) {
    contentfulComponentSiteHeader(
      node_locale: { eq: $node_locale }
      name: { eq: "Miovision.com - Site Header" }
    ) {
      ...ContentfulComponentSiteHeaderFragment
    }
    contentfulComponentSiteFooter(
      node_locale: { eq: $node_locale }
      name: { eq: "Miovision.com - Site Footer" }
    ) {
      ...ContentfulComponentSiteFooterFragment
    }
    contentfulComposePage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      id
      internal {
        type
      }
      slug
      title
      seo {
        createdAt
        updatedAt
        description
        id
        keywords
        pageType
        no_follow
        no_index
        title
        canonicalUrl
        featuredImage {
          description
          file {
            url
          }
        }
      }
      content {
        ... on ContentfulPageLanding {
          id
          internal {
            type
          }
          pageHeader {
            ... on ContentfulComponentHeroBanner {
              ...ContentfulComponentHeroBannerFragment
            }
          }
          sections {
            ... on ContentfulComponentCustomerLogos {
              ...ContentfulComponentCustomerLogosFragment
            }
            ... on ContentfulComponentEventCard {
              ...ContentfulComponentEventCardFragment
            }
            ... on ContentfulComponentEventCardSet {
              ...ContentfulComponentEventCardSetFragment
            }
            ... on ContentfulComponentPlatformFeatureSet {
              ...ContentfulComponentPlatformFeatureSetFragment
            }
            ... on ContentfulComponentPopUp {
              ...ContentfulComponentPopUpFragment
            }
            ... on ContentfulComponentResourceCardSet {
              ...ContentfulComponentResourceCardSetFragment
            }
            ... on ContentfulComponentSolutionsCardCarousel {
              ...ContentfulComponentSolutionsCardCarouselFragment
            }
          }
          footerCta {
            ...ContentfulComponentFooterCtaFragment
          }
          contactForm {
            ... on ContentfulComponentRegistrationForm {
              ...ContentfulComponentRegistrationFormFragment
            }
          }
          microcopy {
            ... on ContentfulGlobalMicrocopyResource {
              ...ContentfulGlobalMicrocopyResourceFragment
            }
          }
        }
      }
    }
  }
`;

const Page: React.FC<PageProps<LandingPageQuery>> = (props) => {
  const { location, data } = props;
  const pageHeader =
    data?.contentfulComposePage?.content?.internal?.type === Constants.PAGE_FLEX
      ? data?.contentfulComposePage?.content?.siteHeader
      : data?.contentfulComponentSiteHeader;
  const pageFooter =
    data?.contentfulComposePage?.content?.internal?.type === Constants.PAGE_FLEX
      ? data?.contentfulComposePage?.content?.siteFooter
      : data?.contentfulComponentSiteFooter;

  return (
    <>
      <SEO
        location={location}
        defaultPageType={data?.contentfulComposePage?.content?.internal?.type}
        defaultTitle={data?.contentfulComposePage?.title}
        seo={data?.contentfulComposePage?.seo as ContentfulComposeSeo}
      />
      <Layout
        location={location}
        page={data?.contentfulComposePage as ContentfulComposePage}
        header={pageHeader as ContentfulComponentSiteHeader}
        footer={pageFooter as ContentfulComponentSiteFooter}
      >
        <PageLanding
          data={data?.contentfulComposePage?.content as ContentfulPageLanding}
        />
      </Layout>
    </>
  );
};

export default Page;
